import axios, {AxiosResponse} from "axios";
import Reminder from "../models/reminder";


class ReminderService {
    private http = axios.create({
        baseURL: 'https://jsonplaceholder.typicode.com/'
    })

    async getReminders() {
        const response = await this.http.get<Reminder[]>('/todos');
        return response.data;
    }

    async addReminder(title: string) {
        const response = await this.http.post<Reminder>('/todos', { title });
        return response.data;
    }

    async removeReminder(id: number) {
        const response = await this.http.delete('todos/' + id);
        return response.data;
    }
}

// class ReminderService {
//     private baseURL: string = 'https://jsonplaceholder.typicode.com/';

//     async getReminders(): Promise<Reminder[]> {
//         const response = await fetch(this.baseURL + 'todos');
//         if (!response.ok) {
//             throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         return await response.json();
//     }

//     async addReminder(title: string): Promise<Reminder> {
//         const response = await fetch(this.baseURL + 'todos', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ title }),
//         });
//         if (!response.ok) {
//             throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         return await response.json();
//     }

//     async removeReminder(id: number): Promise<void> {
//         const response = await fetch(this.baseURL + 'todos/' + id, {
//             method: 'DELETE',
//         });
//         if (!response.ok) {
//             throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//     }
// }


export default new ReminderService();

